*{
    font-family: "Roboto","Helvetica","Arial",sans-serif;
}
.content-wrapper{
    margin: 10px auto;
    max-width: 1000px;
}
.hidden{
    display: none;
}
.message{
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.success-message{
    border: 1px solid green;
    background-color: #d0fdd0;
    padding: 10px;
    margin-bottom: 20px;
    color: green;
}
.notice-message, .warning-message{
    border: 1px solid rgb(148, 60, 1);
    background-color: #ffdeac;
    padding: 10px;
    margin-bottom: 20px;
    color: rgb(148, 60, 1);
}
.error-message{
    border: 1px solid rgb(255, 30, 0);
    background-color: #ffb6ac;
    padding: 10px;
    margin-bottom: 20px;
    color: rgb(148, 1, 1);
}
.small{
  font-size: 12px;
}
.error{
  font-size: 12px;
  color: red;
}
.red{
    color: red;
}
button.add-device{
  margin-bottom: 20px;
}
.desktop-grid .header{
  display: flex;
  margin-bottom: 10px;
}
.desktop-grid .header .column,
.desktop-grid .row .item{
  width: 16%;
}
.desktop-grid .header .column[data-field="edit"],
.desktop-grid .header .column[data-field="remove"],
.desktop-grid .row .item[data-field="edit"],
.desktop-grid .row .item[data-field="remove"]
{
  width: 8%;
}
.desktop-grid .row{
  display: flex;
}
.desktop-grid .body .no-rows{
  text-align: center;
}
.desktop-grid [data-field="edit"]{
  display: flex;
  justify-content: center;
  cursor: pointer;
}
.edit-device input{
  direction: ltr;
}
.edit-device .fields{
  margin-bottom: 20px;
}
.device-accounts .title{
  color: rgba(0, 0, 0, 0.6);
}
.connect-account{
  cursor: pointer;
  text-decoration: underline;
  display: flex;
  margin-bottom: 5px;
}
.connect-account-content{
  display: flex;
}
.connect-account-content [role="button"]{
  padding: 4px 20px;
}
.connect-account-content div{
  margin-left: 5px;
}
.edit-device .device-accounts{
  border: 1px solid #80808069;
  border-radius: 5px;
  padding: 10px 5px;
}
.device-accounts .device-account{
  margin-bottom: 10px;
}
.desktop-grid .device-accounts .title{
  display: none;
}
.device-account{
  display: flex;
}
.device-account .name{
  margin-left: 5px;
}
.device-account .disconnect,
.desktop-grid .remove{
  cursor: pointer;
  background-color: black;
  color: white;
  border-radius: 11px;
  padding: 0px 6px 1px;
  width: fit-content;
}
.double-check .question{
  margin-bottom: 20px;
}
.webcam{
    width: 100%;
    height: auto;
}
.progress-wrapper{
  display: flex;
  justify-content: center;
  margin-top: 20px;
}
.ring-wrapper{
  display: flex;
  justify-content: center;
}
.lds-ring {
  position: relative;
  width: 80px;
  height: 80px;
}
.lds-ring div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 64px;
  height: 64px;
  margin: 8px;
  border: 8px solid #000;
  border-radius: 50%;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: #000 transparent transparent transparent;
}
.lds-ring div:nth-child(1) {
  animation-delay: -0.45s;
}
.lds-ring div:nth-child(2) {
  animation-delay: -0.3s;
}
.lds-ring div:nth-child(3) {
  animation-delay: -0.15s;
}
@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.check-wrapper{
  display: flex;
  justify-content: center;
}
.animated-check {
  height: 50px;
  width: 50px;
}

.animated-check path {
  fill: none;
  stroke: #000;
  stroke-width: 4;
  stroke-dasharray: 23;
  stroke-dashoffset: 23;
  animation: draw 1s linear forwards;
  stroke-linecap: round;
  stroke-linejoin: round
}

@keyframes draw {
  to {
      stroke-dashoffset: 0
  }
}
@media screen and (max-width: 1100px) {

}
@media screen and (max-width: 400px) {

}